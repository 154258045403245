.titulo-priv{
    font-size: 2.5em;
    font-weight: bold;
    color: #000;
    text-align: center;
    margin:20px 0
}
.body-priv{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin:10px 50px
}
.ul-docs{
text-align: start;  list-style: none;
}
 .subtitle-priv{
  font-size: 19px;
  color: #1E8696;
  font-family: 'Montserrat', sans-serif;
  margin-top: 15px;
}
.list-priv{
  list-style: none;
}
.list-priv > li{
  color: #000;
}
.link-serv{
    text-decoration: none;
    color: #1E8696;
  }
@media screen and (min-width: 1000px) {
  .body-priv{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin:10px 250px
}
  
}
@media screen and (min-width: 1400px) {
  .body-priv{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin:10px 350px
}
  
}
@media screen and (min-width: 1800px) {
  .body-priv{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin:10px 450px
}
  
}
@import url("https://fonts.googleapis.com/css2?family=Dosis:wght@400;500&display=swap");

.credit-card {
  position: relative;
  max-width: 520px;
  min-width: 320px;
  margin: 50px auto;
  min-height: 300px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  padding: 24px;
  box-sizing: border-box;
  background: linear-gradient(-240deg, #fffc00, #fc00ff, #00fffc);
  justify-content: space-between;
  font-family: "Dosis", sans-serif;
  overflow: hidden;
}

.credit-card:after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 0;
  color: rgb(249 249 249 / 10%);
  background: linear-gradient(135deg, currentColor 25%, transparent 25%) -100px 0,
    linear-gradient(225deg, currentColor 25%, transparent 25%) -100px 0,
    linear-gradient(315deg, currentColor 25%, transparent 25%),
    linear-gradient(45deg, currentColor 25%, transparent 25%);
  background-size: calc(2 * 100px) calc(2 * 100px);
}

.logo {
  width: 72px;
  display: flex;
  align-self: flex-end;
  filter: drop-shadow(1px 1px 0 #555);
  z-index: 1;
}

.name-and-expiry {
  display: flex;
  justify-content: space-between;
  z-index: 1;
  color: #fff;
  font-size: 22px;
  letter-spacing: 3px;
  filter: drop-shadow(1px 0px 1px #555);
  text-transform: uppercase;
}

.numbers {
  font-size: 36px;
  letter-spacing: 9px;
  text-align: center;
  color: #fff;
  filter: drop-shadow(1px 0px 1px #555);
  z-index: 1;
}
