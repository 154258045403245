@import url("https://fonts.googleapis.com/css2?family=Lora:ital@1&family=Montserrat:wght@100&display=swap");
.card {
  margin: 10px auto;
  box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  height: 96%;
  transition: 0.3s;
}
.card:hover {
  box-shadow: 1px 10px 16px 0 rgba(0, 0, 0, 0.3);
}
.card-title {
  font-family: "Montserrat", sans-serif;
  text-align: center;
  font-size: 26px;
  color: #051f2e;
  font-weight: bold;
}
.montserrat-h6 {
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
}
.montserrat li {
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
}
.montserrat-normas li {
  font-size: 11px;
  font-family: "Montserrat", sans-serif;
}
.title-socio {
  font-size: 60px;
  color: #1e8696;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  letter-spacing: 5px;
}
.subtitle-socio {
  font-size: 19px;
  color: #1e8696;
  font-family: "Montserrat", sans-serif;
}
.margin-serv-1 {
  margin-top: 17px;
}
.margin-serv-2 {
  margin-top: 58px;
}
.sub-title-serv {
  font-family: "Montserrat", sans-serif;
  font-size: 19px;
  color: #051f2e;
  font-style: italic;
}
.title-acordeon {
  color: white !important;
}
.margin-botton {
  margin-bottom: 33px;
}
.card-text {
  font-family: "Montserrat", sans-serif;
  color: #051f2e;
  font-size: 14px;
}

/* .estrechar-texto{
    margin-left: 55px;
    margin-right: 55px;
  } */
.card-image {
  text-align: center;
  margin: 5vh 0;
}
.card-text {
  text-align: left;
  margin-top: 10px;
}
.marg-search-btn2{
  margin-top: 5px;
}
.title-heading {
  font-size: 4.5em;
  font-family: "Oswald", sans-serif;
  font-weight: 400;
  font-size: 35px;

  text-align: center;
  padding: 2.5rem 0 0 0;
}
.searchButton1:hover {
  background-color: #ffc000;
  color: white;
}

.searchButton1 {
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  color: white;
  float: right;
  width: auto;
  padding: 14px 30px;
  border-radius: 40px;
  background: #ffc000;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.4s;
  border: 0;
  font-style: normal !important;
  font-weight: bold;
  text-align: center;
  border: 1px solid #ffc000;
  opacity: 90%;
  letter-spacing: 2px;
}
@media screen and (max-width: 1000px) {
  .title-socio {
    font-size: 40px;
  }
  .subtitle-socio {
    font-size: 16px;
    letter-spacing: 3px;
  }
}
.normas {
  font-size: 11.5px;
}
.align-sociosbtn {
  display: flex;
  justify-content: center;
}
.btn-socios {
  background-color: #ffc000;
  border-color: #ffc000;
  color: white;
  padding: 8px 30px;
  border-radius: 40px;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: bold;
}
.icon-wind {
  width: 60px;
  margin-left: 40%;
}
.p-size {
  font-size: 11px;
  text-decoration: underline;
  margin-left: 70px;
}
.accordion-button:not(.collapsed) {
  background-color: #1e8696;
}
@media screen and (max-width: 376px) {
  .p-size {
    margin-left: 30px;
  }
}
@media screen and (max-width: 1400px) {
  .margin-botton {
    margin-bottom: 19px;
  }
  .margin-botton1 {
    margin-bottom: 32px;
  }
  .p-size {
    margin-left: 60px;
  }
  .marg-search-btn2{
    margin-top: 7px;
  }
}
@media screen and (max-width: 1200px) {
  .card-title {
    font-size: 24px;
  }
  .sub-title-serv {
    font-size: 14px;
  }
  /* .margin-botton {
    margin-bottom: 42px;
  } */
}
