.list-inscripciones{
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0;
}
.list-inscripciones li{
    margin: 30px 0;
}
@import url("https://fonts.googleapis.com/css2?family=Lora:ital@1&family=Montserrat:wght@100&display=swap");
.btn-insc{
    min-width: 260px !important;
    padding: 2rem;
    font-size: 20px;
    font-weight: bold;
    font-family: 'Montserrat', sans-serif;
}
.btn-insc:hover{
    background-color: #fffff2;
    color: #FFC000;
}