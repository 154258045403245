.meteo1 {
    width:400px ;
    height: 480px;
    padding: 10px;
}
.meteo2{
   width:300px;height:250px;border:0;
}
.align-meteo2{
    display:flex;
    align-items: center;
    margin-top: 30px;
    flex-direction: column;
}
     .button-contain{
         display: flex;
         flex-direction: row;
         margin-top: 15px;
     }
    .btn-meteo1 {
        border-radius: 50%;
        width: 150px;
        height: 150px;
        border: none;
        color: #fff;
        font-weight: 900;
        font-size: 1.35rem;
        background:rgb(0, 156, 183) ;
        text-shadow: 0 3px 1px rgb(0, 156, 183);
        box-shadow:rgb(2, 94, 110) 0 8px 0 ,0 15px 20px rgba(0,0,0,.35);
        text-transform: uppercase;
        transition: .4s all ease-in;
        outline: none;
        cursor: pointer;
        text-align: center;
        -webkit-user-select: none;
        -webkit-tap-highlight-color: rgba(0,0,0,0);
        
 
    }
    .btn-directo{
        border-radius: 50%;
width: 150px;
height: 150px;
border: none;
color: #fff;
font-weight: 900;
font-size: 1.5rem;
background: red;
text-shadow: 0 3px 1px rgba(122,17,8,.8);
box-shadow: 0 8px 0 rgb(183,9,0),0 15px 20px rgba(0,0,0,.35);
text-transform: uppercase;
transition: .4s all ease-in;
outline: none;
cursor: pointer;
text-align: center;
-webkit-user-select: none;
-webkit-tap-highlight-color: rgba(0,0,0,0);
    }
    .align-f2-div{
        display: flex;
        align-items: center;
        flex-direction: column;
    }
    .cloud{
        height: 380px;
        width: 740px;
        max-width:max-content;
        margin-left: 50px;
    }
    @media (max-width: 700px){
        .btnSize{
            width: 120px;
        }
    }
    @media (min-width: 1000px){
        .align-meteo2{
            display:flex;
            align-items: center;
            margin-top: 30px;
            flex-direction: column;
        }
        .align-f-div{
            display: flex;
            align-items: center;
            justify-content: space-around;
            flex-direction: row;
        }
        .align-f2-div{
            display: flex;
            align-items: center;
            flex-direction: column;
         margin-top: 30px;
        }
        .cloud{
            height: 380px;
            width: 940px;
            margin-left: 50px;
            max-width:350px;
        }
        #meteo1 {
            margin-right: 50px;
            width: 540px !important;
        }
       
    }