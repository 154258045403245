.logo {
  max-width: 100%;
}
.logo-home {
  padding-bottom: 50px;
}
@media (max-width: 700px) {
  .logo-home {
    width: 100%;
    padding-bottom: 50px;
  }
}
* {
  box-sizing: border-box;
}

:root {
  --white: #fff;
  --primary: #5693d5;
}

.body-home {
  width: 100vw;
  height: 95vh;
  margin: 0;
  overflow: hidden !important;
  font-family: "Source Sans 3", sans-serif !important;
  background: url("https://i.postimg.cc/76nvvS53/fondo-nuevo-2.jpg");
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white);
  position: relative;
}
@media (max-width: 380px) {
  .body-home {
    height: 92vh;
  }
}
.lista-servicios {
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  /* align-content:space-between; */
  padding-left: 0;
}
.imagen-serv-1 {
  width: 55px;
}
.imagen-serv-2 {
  width: 55px;
}
.imagen-serv-3 {
  width: 55px;
}
.imagen-serv-4 {
  width: 55px;
}

.content {
  width: 70%;
  text-align: center;
  margin-top: -100px;
}

.content .logo {
  width: 13%;
  position: absolute;
  top: 1rem;
  left: 2vw;
}

.content .h1-home {
  font-size: 4.5em;
  font-family: "Oswald", sans-serif;
  font-weight: 400;
  font-size: 35px;
  letter-spacing: 5px;
}

@import url("https://fonts.googleapis.com/css2?family=Lora:ital@1&family=Montserrat:wght@100&display=swap");
.h2-home {
  font-size: 29px;
  font-weight: bold;
  position: relative;
  font-family: "Lora", serif;
  margin-top: 50px;
}

.content {
  font-family: "Oswald", sans-serif;
  position: relative;
  top: -30px;
  font-weight: 200;
  margin-top: 30px;
}

.content .social_icons {
  position: fixed;
  right: 2vw;
  bottom: 2vh;
}

.content .social_icons:before {
  content: "";
  width: 1px;
  height: 100%;
  background-color: var(--white);
  position: absolute;
  top: -100%;
}

.content .social_icons a {
  margin: 10px 0px;
  color: var(--white);
  display: block;
  text-decoration: none;
  font-size: 1.5em;
  transition: 0.5s;
}

.content .social_icons a:hover {
  color: var(--primary);
}

.arrow {
  text-align: center;
  margin: 1% 0;
}
.arrow .fa {
  color: var(--white);
  font-weight: 200;
  text-decoration: none;
}
.bounce {
  -webkit-animation: bounce 2s infinite;
  animation: bounce 2s infinite;
}
@-webkit-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}
@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

.content .button {
  position: relative;
  top: -10px;
}

.searchBox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 50%);
  border-radius: 40px;
  padding: 5px 10px;
  display: flex;
}
.searchButton:hover {
  background-color: #ffc000;
  color: white;
}

.searchButton {
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  color: white;
  float: right;
  width: auto;
  padding: 14px 30px;
  border-radius: 40px;
  background: #ffc000;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.6s;
  border: 0;
  font-style: normal !important;
  font-weight: bold;
  text-align: center;
  border: 1px solid #ffc000;
  opacity: 90%;
  letter-spacing: 2px;
}
@media screen and (max-width: 380px) {
  .searchButton {
    padding: 15px 20px;
  }
  .content .h1-home {
    font-size: 2em;
  }
  .logo-home {
    width: 80%;
    padding-bottom: 40px;
  }
}

.searchInput {
  border: none;
  background: none;
  outline: none !important;
  float: left;
  padding: 0;
  color: white;
  font-size: 16px;
  transition: 0.4s;
  width: 0px;
}
.searchButton:hover{
  background-color: #fffff2;
  color: #FFC000;
}

@media screen and (max-width: 1020px) {
  .content .h1-home {
    font-size: 2em;
  }
  .h2-home {
    font-size: 23px;
  }
  .content .h2-home {
    position: relative;
    top: -10px;
    margin-top: 10px;
  }
  .body-home {
    background: url("https://i.postimg.cc/bNFGntmN/movil-nuevo.jpg");
    width: 100vw;
    height: 93vh;
    margin: 0;
    overflow: hidden !important;
    font-family: "Source Sans 3", sans-serif !important;
    background-position: center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--white);
    position: relative;
  }
}

@media screen and (max-width: 820px) {
  .searchButton {
    letter-spacing: 0px;
    font-weight: bold;
  }
  .content {
    width: 100%;
    padding: 1rem;
  }
  .content .logo {
    width: 20%;
  }
  .content .h1-home {
    font-size: 2.5em;
  }
  .content .h2-home {
    position: relative;
    top: -10px;
  }
  .content .social_icons {
    position: absolute;
    width: 100%;
    right: 0vw;
    bottom: 1vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .content .social_icons a {
    margin: 0 15px;
  }

  .content .social_icons:before {
    content: "";
    width: 0px;
    height: 0%;
    top: 00%;
  }
}
@media screen and (max-width: 410px) {
  .searchButton {
    font-size: 13px;
  }
}
@media screen and (max-width: 355px) {
  .searchButton {
    font-size: 11px;
  }
}
/* WHATSAPP */
.styles-module_floatingWhatsapp__1akvz {
  z-index: 5 !important;
}
