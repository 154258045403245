@import url('https://fonts.googleapis.com/css?family=Barlow:300,400,500,700,800');
 * {
	 box-sizing: border-box;
}
@import url("https://fonts.googleapis.com/css2?family=Lora:ital@1&family=Montserrat:wght@100&display=swap");

.img-about{
	max-width: 100%;
	margin-left: auto;
    margin-right: auto;
	display: block;
	margin-top: 40px;
}
.acord-about{
	margin:20px 0;
	max-width: 1655px;
	text-align: center;
}
.text-about{
	font-size: 14px;
	color:#051F2E;
	font-family: 'Montserrat', sans-serif;
	margin:0 10px
}
.list-about{
	text-align: left;
}
.body-card-about{
	display: flex;
	flex-direction: column;
	align-items: center;
}
@media screen and (min-width: 1650px) {
	.acord-about{
		margin-left: auto;
		margin-right: auto;
	}
  }
  @media screen and (min-width: 1000px) {
	.text-about{
		font-size: 21px;
		
	}
  }

