* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

/* FUENTES */
@import url(https://fonts.googleapis.com/css?family=Oswald:300,400,700);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,300,400,600,700,900,200italic,300italic,400italic,600italic,700italic,900italic);
/* FIN FUENTES */

#meteo1 {
  width: 410px !important;
  height: 500px;
  padding: 10px;
}
/* SIDE NAV */
.logo-side-marg {
  margin-bottom: -40px !important;
}
.sideBar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: left;
}
.sideBar > a {
  text-decoration: none;
  color: black;
  margin: 3rem 0;
  font-size: 20px;
}
#align-haztesocio {
  margin-bottom: -0.5rem !important;
}
.dropdown-item {
  font-size: 20px;
}
.nav-link {
  color: black;
  font-size: 20px;
}
.nav-link:hover {
  color: black;
}
.navbar-light .navbar-toggler-icon {
  background-image: url("https://i.postimg.cc/PqHSPDP0/hamburguesa.png");
}
.navbar-light .navbar-brand {
  color: white;
}

.navbar-light .navbar-toggler {
  color: none;
  border: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: none;
}
.vr-align {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;
}
.align-nav-vr{
  display:flex;
  flex-direction: row;
}

@media screen and (max-width: 768px) {
  .resp-nav {
    display: none;
  }
  /* #navbarScrollingDropdown{
    padding: 3.8rem 1rem .5rem 1rem !important
  } */
  .dropdown-menu {
    position: static;
    z-index: 1000;
    display: none;
    min-width: 10rem;
    padding: 0;
    margin: 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: none;
    border-radius: 0.25rem;
  }
}
@media screen and (min-width: 768px) {
  .resp-nav1 {
    display: none;
  }
  .nav-link {
    margin: 0 20px;
  }
  .lista-servicios {
    padding-bottom: 15px;
  }
}
@media screen and (max-width: 380px) {
  .navbar-brand {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    /* margin-bottom: -30px !important; */
  }

  .sideBar > a {
    text-decoration: none;
    color: black;
    margin: 1.8rem 0;
    font-size: 20px;
  }
  .offcanvas-header {
    padding: 1rem 1rem 0rem 1rem;
  }
}

/* VIDEO */
.bg-video-wrap {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 80vh;
  margin-bottom: -25px;
}
video {
  min-width: 100%;
  max-height: 100vh;
  max-width: 100%;
  z-index: 1;
  object-fit: cover;
}
.overlay {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  background-image: linear-gradient(
    45deg,
    rgba(0, 0, 0, 0.3) 3%,
    rgba(0, 0, 0, 0.3) 3%
  );
  background-size: 3px 3px;
  z-index: 2;
}
.h1-video {
  text-align: center;
  color: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 3;
  width: 80%;
  height: 50px;
}

/* MODAL */

.private-modal {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.button-modal {
  margin-top: 15px;
  background-color: #ffc000;
  color: white;
  width: 170px;
  font-weight: bold;
  border-radius: 25px;
  border: none;
  height: 40px;
}
.btn-private {
  background-color: #ffc000;
  border-color: #ffc000;
  color: white;
}
.btn-primary{
  background-color: #ffc000 ;
  border: none;
}
.input-private {
  border-radius: 10px;
  width: 170px;
  height: 40px;
}

/*======================
    VR
=======================*/
.mapsConsumerUiSceneInternalCoreScene__widget-scene-canvas {
  position: absolute;
  left: 0;
  top: 0;
}
#vr {
  display: flex;
  justify-content: center;
  width: 99%;
  height: 75vh;
}
.vr-text {
  display: flex;
  justify-content: center;
}
.vr-h2 {
  text-align: center;
}
/*======================
    404 page
=======================*/

.page_404 {
  padding: 40px 0;
  background: #fff;
  font-family: "Arvo", serif;
}

.page_404 img {
  width: 100%;
}

.four_zero_four_bg {
  background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
  height: 400px;
  background-position: center;
}

.four_zero_four_bg h1 {
  font-size: 80px;
}

.four_zero_four_bg h3 {
  font-size: 80px;
}

.link_404 {
  color: #fff !important;
  padding: 10px 20px;
  background: #39ac31;
  margin: 20px 0;
  display: inline-block;
}
.contant_box_404 {
  margin-top: -50px;
}

@media screen and (min-width: 1000px) {
  .page_404 {
    margin-left: 160px;
  }
}

/* SERVICIOS */
.title-socio {
  font-size: 60px;
  color: #1e8696;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
}
.subtitle-socio {
  font-size: 19px;
  color: #1e8696;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 40px;
}
@media screen and (min-width: 768px) {
  .body-servicios {
    display: flex;
    align-items: center !important;
    flex-direction: row !important;
    flex-wrap: wrap;
    justify-content: center;
  }
  .container-serv {
    margin: 0 40px;
  }
}
@media screen and (max-width: 380px) {
  .container-serv {
    max-width: 90% !important;
  }
}
.body-servicios {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.head-serv {
  font-size: 4.5em;
  font-family: "Oswald", sans-serif;
  font-weight: 400;
  font-size: 35px;
  letter-spacing: 5px;
  padding-top: 1.5rem;
  text-align: center;
  color: #1a1a1a;
}
@media screen and (max-width: 750px) {
  .head-serv {
    padding-top: 0rem;
  }
}
.head-serv2 {
  font-family: "Oswald", sans-serif;
  text-align: center;
}
.container-serv {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80vh;
  box-sizing: border-box;
  line-height: 1.5;
  font-family: "Open Sans", sans-serif;
  max-width: 100%;
}
.card-serv {
  position: relative;
  background: #f6f6f6;
  width: 400px;
  height: 67vh;
  border-radius: 6px;
  padding: 2rem;
  color: #aaa;
  box-shadow: 0 0.25rem 0.25rem #009b8a, 0 0 1rem #009b8a;
  overflow: hidden;
}
.card__image-container {
  margin: -2rem -2rem 1rem -2rem;
}
.card__line {
  opacity: 0;
  animation: LineFadeIn 0.8s 0.8s forwards ease-in;
}

.card__image {
  opacity: 0;
  animation: ImageFadeIn 0.8s 1.4s forwards;
  max-width: 100%;
}
.card__title {
  color: black;
  margin-top: 0;
  font-weight: 800;
  letter-spacing: 0.01em;
}
.card__content {
  margin-top: -1rem;
  opacity: 0;
  animation: ContentFadeIn 0.8s 1.6s forwards;
}
.card__svg {
  position: absolute;
  left: 0;
  top: 96px;
}
@keyframes LineFadeIn {
  0% {
    opacity: 0;
    d: path(
      "M 0 300 Q 0 300 0 300 Q 0 300 0 300 C 0 300 0 300 0 300 Q 0 300 0 300 "
    );
    stroke: #fff;
  }
  50% {
    opacity: 1;
    d: path(
      "M 0 300 Q 50 300 100 300 Q 250 300 350 300 C 350 300 500 300 650 300 Q 750 300 800 300"
    );
    stroke: #009b8a;
  }
  100% {
    opacity: 1;
    d: path(
      "M -2 100 Q 50 200 100 250 Q 250 400 350 300 C 400 250 550 150 650 300 Q 750 450 802 400"
    );
    stroke: #009b8a;
  }
}
@keyframes ContentFadeIn {
  0% {
    transform: translateY(-1rem);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes ImageFadeIn {
  0% {
    transform: translate(-0.5rem, -0.5rem) scale(1.05);
    opacity: 0;
    filter: blur(2px);
  }
  50% {
    opacity: 1;
    filter: blur(2px);
  }
  100% {
    transform: translateY(0) scale(1);
    opacity: 1;
    filter: blur(0);
  }
}

/* SERCICIOS 2 */
@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,600);
@media screen and (max-width: 1000px) {
  .title-socio {
    font-size: 40px;
  }
  .subtitle-socio {
    font-size: 16px;
  }
}

.body-serv-card {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 35px;
}
.snip1336 {
  font-family: "Roboto", Arial, sans-serif;
  position: relative;
  overflow: hidden;
  margin: 10px;
  min-width: 230px;
  max-width: 315px;
  width: 100%;
  color: #ffffff;
  text-align: left;
  line-height: 1.4em;
  background-color: #54808c;
}
.snip1336 * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
}
.snip1336 img {
  max-width: 100%;
  vertical-align: top;
  opacity: 0.85;
}
.snip1336 figcaption {
  width: 100%;
  background-color: #54808c;
  padding: 25px;
  position: relative;
}
.width-serv-image {
  min-width: 100%;
}
.snip1336 figcaption:before {
  position: absolute;
  content: "";
  bottom: 100%;
  left: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 55px 0 0 400px;
  border-color: transparent transparent transparent #54808c;
}
.snip1336 figcaption a {
  padding: 5px;
  border: 1px solid #ffffff;
  color: #ffffff;
  font-size: 0.7em;
  text-transform: uppercase;
  margin: 10px 0;
  display: inline-block;
  opacity: 0.65;
  width: 47%;
  text-align: center;
  text-decoration: none;
  font-weight: 600;
  letter-spacing: 1px;
}
.snip1336 figcaption a:hover {
  opacity: 1;
}
.snip1336 .profile {
  border-radius: 50%;
  position: absolute;
  bottom: 100%;
  left: 25px;
  z-index: 1;
  max-width: 90px;
  opacity: 1;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
}
.snip1336 .follow {
  margin-right: 4%;
  border-color: #2980b9;
  color: #2980b9;
}
.snip1336 h2 {
  margin: 0 0 5px;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
  font-size: 28px;
}
.snip1336 h2 span {
  display: block;
  font-size: 0.5em;
  color: #2980b9;
}
.snip1336 p {
  margin: 0 0 10px;
  font-size: 14px;
  letter-spacing: 1px;
  opacity: 0.8;
  font-family: "Montserrat", sans-serif;
}


/* PRIVACIDAD */
.privacy-p  p{
text-align: justify;
font-size: 12px;
margin:0 10px;
margin-bottom: 30px;
}
@media screen and (min-width: 1000px) {
  .privacy-p  p{
    font-family: "Montserrat", sans-serif;
    text-align: justify;
    font-size: 12px;
    margin:0 30%;
    margin-bottom: 30px;
    }
    .margin-legacy{
      margin-bottom: 10.5%;
    }
}
@media screen and (min-width: 760px) {
    .margin-legacy{
      margin-bottom: 10.5%;
    }
}