.gray {
    color: #a5a5a5;
  }
  
  .team{
    margin:40px;
  }
  .staff{
    font-size:4.5em;
    font-family: 'Oswald', sans-serif;
    font-weight:400;
    font-size: 35px;
    letter-spacing: 5px;
  }
  
  .team-member {
    margin: 15px 0;
    padding: 0;
  }
  
  .team-member figure {
    position: relative;
    overflow: hidden;
    padding: 0;
    margin: 0;
  }
  
  .team-member figure img {
    min-width: 100%;
  }
  
  .team-member figcaption p {
    font-size: 16px;
  }
  
  .team-member figcaption ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .team-member figcaption ul {
    visibility: visible;
    -webkit-transition: all 0.1s ease-in-out;
    -moz-transition: all 0.1s ease-in-out;
    -o-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
  }
  
  .team-member figcaption ul li {
    display: inline-block;
    padding: 10px;
  }
  
  .team-member h4 {
    margin: 10px 0 0;
    padding: 0;
  }
  
  .team-member figcaption {
    padding: 50px;
    color: transparent;
    background-color: transparent;
    position: absolute;
    z-index: 996;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0;
    overflow: hidden;
    visibility: hidden;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  
  .team-member figure:hover figcaption {
    visibility: visible;
    color: #fff;
    background: #FFC000;
    opacity: 93%;
    /* Primary color, can be changed via colors.css */
    
    height: 100%;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  
  .team-member figure:hover figcaption ul li a:hover {
    color: rgba(49, 49, 49, .97);
  }
  
  .team-member figure img {
    -webkit-transform: scale(1) rotate(0) translateY(0);
    -moz-transform: scale(1) rotate(0) translateY(0);
    -o-transform: scale(1) rotate(0) translateY(0);
    -ms-transform: scale(1) rotate(0) translateY(0);
    transform: scale(1) rotate(0) translateY(0);
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  }

  /* .img-responsive{
max-width: 50% !important;
  } */
  
  .team-member figure:hover img {
    -webkit-transform: scale(1.1) rotate(1deg) translateY(12px);
    -moz-transform: scale(1.1) rotate(1deg) translateY(12px);
    -o-transform: scale(1.1) rotate(1deg) translateY(12px);
    -ms-transform: scale(1.1) rotate(1deg) translateY(12px);
    transform: scale(1.1) rotate(1deg) translateY(12px);
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  }