@import url(https://fonts.googleapis.com/css?family=Montserrat:500);


.body-gallery {
	min-height: 100vh;
	background-color: #fafafa;
	box-sizing: border-box;
	font-size: 10px;
}

.container-gallery {
	max-width: 100rem;
	margin: 0 auto;
	padding: 0 2rem 2rem;
}
.gallery-align-h1{
	text-align: center;
	padding: 40px 0 0 0;
}

.heading span {
	display: block;
}

.gallery {
	display: flex;
	flex-wrap: wrap;
	/* Compensate for excess margin on outer gallery flex items */
	margin: -1rem -1rem;
}

.gallery-item {
	/* Minimum width of 24rem and grow to fit available space */
	flex: 1 0 24rem;
	/* Margin value should be half of grid-gap value as margins on flex items don't collapse */
	margin: 1rem;
	box-shadow: 0.3rem 0.4rem 0.4rem rgba(0, 0, 0, 0.4);
	overflow: hidden;
}

.gallery-image {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;
	transition: transform 400ms ease-out;
}

.gallery-image:hover {
	transform: scale(1.15);
}
@media (max-width: 991px) {
	.gallery {
		grid-template-columns: repeat(auto-fit, minmax(14rem, 1fr));

	}
    
  }


@supports (display: grid) {
	.gallery {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(24rem, 1fr));
		grid-gap: 2rem;
	}
}


@media (max-width: 376px) {
	.gallery-item {
		margin-right: 3.6rem;
		
	}
    
  }