@import url("https://fonts.googleapis.com/css2?family=Lora:ital@1&family=Montserrat:wght@100&display=swap");

.astAN {
  font-size: 11px;
  line-height: 16px;
  color: black;
}
.astAN > a {
  color: #ffc000;
}
.p-act {
  color: #051F2E;
  font-size: 23px;
  line-height: 21px;
  font-family: 'Montserrat', sans-serif;
  margin: auto 250px
}
.h3-act{
  font-family: 'Montserrat', sans-serif;
}
.p-act-1 {
  color: #051F2E;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  line-height: 21px;
  margin: 2px;
  font-weight: 400;
}
.p-act-2 {
  color: #051F2E;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  line-height: 21px;
  margin: 2px;
  font-weight: 400;
}
.p-act-3 {
  text-align: center;
}
.p-act-4 {
 text-align: center;
}
.lista-grupos {
  font-size: 20px;
  text-align: left;
  font-weight: 400;
}
.title-none {
  text-align: center;
}
.duracion {
  font-family: "Montserrat", sans-serif;
  font-style: italic;
  font-size: 25px;
  color: #051f2e;
  text-transform: uppercase;
  font-weight: bold;
}
@media (max-width: 1000px) {
  .p-act {
    color: black;
    font-size: 14px;
    line-height: 17px;
    text-align: start;
  }
}

.align-card {
  display: flex;
  flex-direction: row;
}
.button-act-align{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.subtitle-socio1 {
  font-size: 19px;
  color: #1e8696;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 0px;
}
.button-act {
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  color: white;
  float: right;
  text-decoration: none;
  width: auto;
  padding: 0px 30px;
  border-radius: 40px;
  background: #ffc000;
  transition: 0.6s;
  border: 0;
  font-style: normal !important;
  font-weight: bold;
  text-align: center;
  border: 1px solid #ffc000;
  opacity: 90%;
  letter-spacing: 2px;
}
.button-act:hover {
  background-color: #ffc000;
  color: white;
}

.list-act {
  list-style: none;
  padding-left: 0;
  text-align: center;
  font-size: 24px;
  font-weight: 100;
}
.title-act {
  color: white;
  background-color: #1e8696;
  padding: 13px;
  font-size: 30px;
}
.title-act-1 {
  color: white;
  background-color: #1e8696;
  font-family: "Montserrat", sans-serif;
  font-size: 25px;
  text-transform: uppercase;
  margin-left: auto;
}
.price {
  font-size: 25px;
  color: #1e8696;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
}
.container3 {
  width: 100%;
  min-height: 600px;
  display: flex;
  flex-wrap: wrap;
}
.img-act1 {
  max-width: 100%;
  max-height: 900px;
  min-width: 100%;
}
.img-act {
  display: flex;
  justify-content: center;
}

@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,800");

.containerAN {
  width: 95%;
  margin: 0 auto;
  font-size: 18px;
  font-family: "Open Sans", sans-serif;
  min-width: 285px;
  margin-bottom: 60px;
}

/* Banner - shared styles */
.banner {
  padding: 0.2rem 0.5rem;
  margin: 1rem;
  color: white;
  line-height: 50px;
  font-size: 30px;
  letter-spacing: 0.1pt;
  font-weight: 800;
  position: relative;
  cursor: pointer;
  transition: all 200ms ease-in;
  box-sizing: border-box;
}

.banner:hover {
  transform: scale(1.02);
}

/* Banner text - shared styles */
.headline {
  color: white;
  line-height: 50px;
  font-size: 30px;
  font-weight: 800;
  position: absolute;
  margin-top: 135px;
  position: absolute;
}
/* Banner SHOP NOW btn styles */
.shop-btn {
  position: absolute;
  margin-top: 185px;
  font-size: 20px;
  padding: 5px 10px;
  background: transparent;
  border: 2px solid #dfdfdf;
  color: white;
  font-weight: 800;
  transition: all 400ms ease-in-out;
  cursor: pointer;
}

.shop-btn:hover {
  background: white;
  border: 2px solid transparent;
  color: #303030;
}
.banner-two .headline {
  position: absolute;
  top: 5px;
  margin: 0px;
  font-size: 18px;
  font-weight: 800;
  line-height: 25px;
}

.banner-three {
  min-height: 70px;
}

.banner-three .headline {
  line-height: 40px;
  margin-top: 100px;
}

.banner-four {
  background: url("https://i.postimg.cc/1zVn0Vtx/s1-2.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 190px;
}

.banner-four .headline {
  font-size: 40px;
  line-height: 50px;
  position: absolute;
  bottom: 80px;
}

.banner-four .subline {
  position: absolute;
  bottom: 10px;
  line-height: 35px;
}

.clear-fix {
  clear: both;
}
.accordion-item:last-of-type .accordion-button.collapsed {
  background-color: #1e8696;
  height: 70px;
}

@media (min-width: 800px) {
  .banner-one,
  .banner-two {
    width: 47%;
    float: left;
    margin-right: 0px;
    margin-bottom: 0px;
  }
}
@media (max-width: 1300px) {
  .none-yoga{
    display: none;
  }
}

@media (min-width: 1000px) {
  .banner-one,
  .banner-two {
    width: 33%;
  }
  .p-act-1 {
    margin: auto 150px;
  }
  .p-act-2 {
    margin: auto 50px;
  }
  .p-act-3 {
    text-align: center;
  }
  .p-act-4 {
   text-align: center;
  }

  .banner-three {
    width: 68%;
    min-height: 10px;
  }
  .banner-three-title {
    width: 68%;
    min-height: 0px;
  }

  .banner-four {
    position: absolute;
    width: 29%;
    top: 0px;
    right: 0px;
    height: 617px;
    margin-top: 118px;
    min-height: 715px;
  }
}

@media (min-width: 1400px) {
  .banner-four {
    width: 30%;
  }
}
@media (max-width: 1000px) {
  .title-none {
    display: none;
  }
}

/* YOGA */

.banner-four-yoga {
  background: url("https://i.postimg.cc/rmRRq0Wy/s2.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 190px;
}

.banner-four-yoga .headline {
  font-size: 40px;
  line-height: 50px;
  position: absolute;
  bottom: 80px;
}

.banner-four-yoga .subline {
  position: absolute;
  bottom: 10px;
  line-height: 35px;
}
@media (min-width: 1000px) {
  .banner-four-yoga {
    position: absolute;
    width: 29%;
    top: 0px;
    right: 0px;
    height: 617px;
    margin-top:118px;
    min-height: 715px;
  }
}
@media (min-width: 1400px) {
  .banner-four-yoga {
    width: 30%;
  }
}

/* campus*/

.banner-four-campus {
  background: url("https://i.postimg.cc/X7xCn0hP/s4.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 190px;
}
.tachado{
  text-decoration:line-through;
}
.align-tachado{
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:center
}
.banner-four-campus .headline {
  font-size: 40px;
  line-height: 50px;
  position: absolute;
  bottom: 80px;
}

.banner-four-campus .subline {
  position: absolute;
  bottom: 10px;
  line-height: 35px;
}
.accordion-margin {
  margin-top: 10px !important;
}
@media (min-width: 1000px) {
  .banner-four-campus {
    position: absolute;
    width: 29%;
    top: 0px;
    right: 0px;
    height: 617px;
    margin-top: 100px;
    min-height: 715px;
  }
}
@media (min-width: 1400px) {
  .banner-four-campus {
    width: 30%;
  }
}

/* GRUPOS */
.banner-four-grupo {
  background: url("https://i.postimg.cc/fRL0LrQR/s3.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 190px;
}
.banner-four-grupo .headline {
  font-size: 40px;
  line-height: 50px;
  position: absolute;
  bottom: 80px;
}

.banner-four-grupo .subline {
  position: absolute;
  bottom: 10px;
  line-height: 35px;
}
@media (min-width: 1000px) {
  .banner-four-grupo {
    position: absolute;
    width: 29%;
    top: 0px;
    right: 0px;
    height: 617px;
    margin-top: 100px;
    min-height: 685px;
  }
}
@media (min-width: 1400px) {
  .banner-four-grupo {
    width: 30%;
  }
}

@media (max-width: 1400px) {
  .campus-resp{
    font-size:22px !important
  }
}
@media (max-width: 1300px) {
  .campus-resp{
    font-size: 20px !important;
  }
}
@media (max-width: 1200px) {
  .campus-resp{
    font-size: 18px !important;
  }
}

@media only screen and (min-width: 800px) and (max-width: 900px) {
  .lista-grupos {
    line-height: 40px;
    font-size: 16px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1550px) {
  .lista-grupos {
    line-height: 49.5px;
    font-size: 16px !important;
  }
}
@media only screen and (min-width: 800px) and (max-width: 1550px) {
  .meses {
    display: none;
  }
}
